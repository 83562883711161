import * as $ from 'jquery';

export class ClickToCall {
    public Setup() {
        const PhoneRegEx = new RegExp(/(1[\s.(+-])?([(]?\b\d{3}[)]?[(\s)?.-]\d{3}[\s.-]\d{4})(?![^<]*>|[^<>]*<\/)/g);
        //search through the immediate children of the body and construct a new jQuery object from the matching elements.
        $('body *').not('iframe, script, label, label *').contents().each((i, el) => {
            //nodeType 3 = text node
            if (el.nodeType === 3 && el.nodeValue.trim().length && !$(el).closest('a').length) {
                let node = el.nodeValue;
                let match = node.match(PhoneRegEx);
                if (match) {
                    let NewHtml = node.replace(PhoneRegEx, (match) => {
                        //remove spaces, dashes, dots and parenthesis for the href
                        let PhoneNumber = match.replace(/[ -.()]/g, "");
                        return `<a href="tel:${PhoneNumber}">${match}</a>`;
                    });
                    $(el).replaceWith(NewHtml);
                }
            }
        });
    }

    //check to see if on a mobile device
    public IsMobileDevice() {
        return (/iPhone|iPad|iPod|Android|Windows Phone/i.test(navigator.userAgent));
    }

    //call this for infinite scrolling
    public InfiniteScrollClickToCall() {
        this.Setup();
    }
}

export function Create() {
    return new ClickToCall();
}
